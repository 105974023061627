const en = {
  home: {
    header: {
      title: '{recordingDurationInMin}-min Emotional Wellbeing Survey',
      poweredby: {
        title: 'powered by',
      },
      button: {
        title: 'Get Started',
      },
    },
    instructions: {
      title: 'How it works',
      findQuietPlace: {
        title: 'Find a quiet place',
      },
      record60Secs: {
        title: 'Record for {recordingDurationInSec} seconds',
      },
      receiveInsights: {
        title: 'Receive insights on your mood',
      },
    },
    results: {
      failureNote: 'Failed to score the session',
      thankYou: 'Thank You!',
      successNote: '*These scores are for demo purposes only',
      labels: {
        stress: 'Stress Score',
        anxiety: 'Anxiety Score',
        depression: 'Depression Score',
      },
    },
  },
};

export default en;
