import React from 'react';
import './style.scss';

const SOCIAL_MENUS = [
  {
    title: 'Twitter',
    alt: 'Twitter',
    img: <i className="fab fa-twitter-square" />,
    link: 'https://twitter.com/EllipsisHealth?s=20',
  },
  {
    title: 'LinkedIn',
    alt: 'LinkedIn',
    img: <i className="fab fa-linkedin" />,
    link: 'https://www.linkedin.com/company/ellipsis-health/',
  },
  {
    title: 'Youtube',
    alt: 'Youtube',
    img: <i className="fab fa-youtube-square" />,
    link: 'https://www.youtube.com/channel/UCIbmPilEMML0Hh3VhQ7GAoQ',
  },
];

const SocialMenuList = () => (
  <div className="social-menu-list">
    {SOCIAL_MENUS.map((menu) => (
      <div className="social-menu-list__item" title={menu.title} key={menu.title}>
        <a href={menu.link} aria-label={menu.alt}>
          {menu.img}
        </a>
      </div>
    ))}
  </div>
);

export default SocialMenuList;
