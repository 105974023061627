import React from 'react';
import classnames from 'classnames';
import VoiceTool from '../../components/VoiceTool';
import Results from './Results';

interface Props {
  state: 'started' | 'finished' | null;
  scoreMessage?: ScoreMessage;
}

const MediaBox: React.FC<Props> = ({
  state,
  scoreMessage,
}) => {
  const flipped = state === 'finished';
  return (
    <div className={classnames('media-box', `media-box--${state}`)} id="get-started">
      <div className="container">
        <div className={classnames('voice-tool-area flipper', flipped ? 'flipped' : '')}>
          <div className="flipper__area">
            <div className="front">
              <VoiceTool />
            </div>
            <div className="back">
              <Results
                result={scoreMessage}
                error={
                                    scoreMessage?.data.score?.measures.anxietyScore === -1
                                    && scoreMessage?.data.score?.measures.depressionScore === -1
                                    && scoreMessage?.data.score?.measures.stressScore === -1
                                }
              />
            </div>
          </div>
        </div>
        <div className="circle e-47" />
        <div className="circle e-44" />
      </div>
    </div>
  );
};

export default MediaBox;
