import React from 'react';
import EHLogo from '../../components/EHLogo';
import SocialMenuList from '../../components/SocialMenuList';

const Footer = () => (
  <div className="footer">
    <div className="footer__content container">
      <EHLogo />
      <SocialMenuList />
    </div>
  </div>
);

export default Footer;
