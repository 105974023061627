import React from 'react';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { FormatMessage } from '../../i18n';
import { RECORDING_DURATION_IN_SEC, RECORDING_DURATION_IN_MIN } from '../../constants';
// import ImgRecordUrl from '../../assets/img/record-1.png';
// import ImgTimerUrl from '../../assets/img/timer-1.png';
// import ImgTopicUrl from '../../assets/img/topic-1.png';

const INSTRUCTIONS_LIST = [
  {
    id: 'findQuietPlace',
    imgUrl: null, // ImgTopicUrl,
    Icon: ArticleOutlinedIcon,
    alt: 'Find quiet place',
    title: <FormatMessage id="home.instructions.findQuietPlace.title" />,
  },
  {
    id: 'record60Secs',
    imgUrl: null, // ImgTimerUrl,
    Icon: TimerOutlinedIcon,
    alt: 'Record 60 secs',
    title: (
      <FormatMessage
        id="home.instructions.record60Secs.title"
        values={{
          recordingDurationInSec: RECORDING_DURATION_IN_SEC,
          recordingDurationInMin: RECORDING_DURATION_IN_MIN,
        }}
      />
    ),
  },
  {
    id: 'receiveInsights',
    imgUrl: null, // ImgRecordUrl,
    Icon: MicNoneOutlinedIcon,
    alt: 'Receive insights',
    title: <FormatMessage id="home.instructions.receiveInsights.title" />,
  },
];

type Props = {
  onGetStarted: () => void;
};

const Instructions: React.FC<Props> = ({ onGetStarted }) => (
  <div className="instructions">
    <div className="container">
      <h2 className="title">
        <FormatMessage id="home.instructions.title" />
      </h2>
      <div className="instruction__list">
        {
          INSTRUCTIONS_LIST.map((item) => (
            <div className="instruction__item" key={item.id}>
              <div className="instruction__icon">
                {
                  item.imgUrl
                    ? <img src={item.imgUrl} alt={item.alt} />
                    : <item.Icon sx={{ fontSize: '3.5rem', color: 'var(--color-primary)' }} />
                }
              </div>
              <h3 className="instruction__title">{item.title}</h3>
            </div>
          ))
        }
      </div>
      <div className="section-get-started hidden mobile-visible">
        <button className="button button--solid" onClick={onGetStarted} type="button">
          <FormatMessage id="home.header.button.title" />
        </button>
      </div>
    </div>
  </div>
);

export default Instructions;
