import React from 'react';
import { Box } from '@mui/material';
import { ReactComponent as EHLogoSvg } from '../../assets/img/logos/ellipsis-health.svg';
import EHLogo from '../../components/EHLogo';
import {
  RECORDING_DURATION_IN_SEC, RECORDING_DURATION_IN_MIN, LANGUAGES, Language,
} from '../../constants';
import { FormatMessage } from '../../i18n';
import NTT_AT_LogoUrl from '../../assets/img/logos/NTT_AT_logo.png';
import LangList from '../../components/LangList';

type Props = {
  onGetStarted: () => void;
  selectedLang: Language;
  // onSelectLang: (lang: Language) => void;
};

const Header: React.FC<Props> = ({
  onGetStarted,
  selectedLang,
  // onSelectLang,
}) => (
  <div className="header">
    <div className="container">
      <div className="eh-logo-list">
        <div className="logo-first">
          <EHLogo className="eh-logo--primary">
            <img src={NTT_AT_LogoUrl} alt="NTT-AT" />
          </EHLogo>
          <LangList
            langs={LANGUAGES}
            selected={selectedLang}
            // onSelect={onSelectLang}
          />
        </div>
        <div className="flex-fill" />
        <EHLogo className="eh-logo--secondary">
          <Box sx={{ marginBottom: '0.25rem', textAlign: 'center' }}>
            <FormatMessage id="home.header.poweredby.title" />
          </Box>
          <EHLogoSvg />
        </EHLogo>
      </div>

      <div className="title-section">
        <h1 className="title">
          <FormatMessage
            id="home.header.title"
            values={{
              recordingDurationInSec: RECORDING_DURATION_IN_SEC,
              recordingDurationInMin: RECORDING_DURATION_IN_MIN,
            }}
          />
        </h1>
        <a className="button" href="#get-started" target="_self" onClick={onGetStarted}>
          <FormatMessage id="home.header.button.title" />
        </a>
      </div>
      <div className="circle e-42" />
      <div className="circle e-45" />
      <div className="circle e-46" />
    </div>
  </div>
);

export default Header;
